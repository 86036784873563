import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconButton } from "../components/IconButton";
import { Loading } from "../components/Loading";
import { LoginWithGoogle, tryGetOrgName } from "./LoginPanel";

export const SignupPanel = () => {

	const initOrg = tryGetOrgName();

	const [login, setLogin] = useState({ person: "", email: "", password: "", password2: "", organization: initOrg || "" });
	const [isSending, setSending] = useState(false);
	const [status, setStatus] = useState({ msg: "", err: false });
	const navigate = useNavigate();

	const handleSubmit = async (e?: any) => {
		if (e)
			e.preventDefault();
	
		const { email, password } = login;
		if (!email || !password) return;

		setSending(true);
		const data = new URLSearchParams();
		data.append("organization", login.organization);
		data.append("person", login.person);
		data.append("username", email);
		data.append("password", password);
		const resp = await fetch("/api/signup", {
			method: "POST",
			body: data
		});

		try {
			const text = await resp.text();
			if (!resp.ok) {
				setStatus({ msg: "Invalid Username or password", err: true });
			}
			else {
				setStatus({ msg: "Welcome back!", err: false });
				setTimeout(() => navigate("/", { replace: true }), 700);
			}
		}
		catch {
			//resp.ok
		}
		setSending(false);
	}
	let pwdMatch = true;
	if (login.password && login.password2 && login.password !== login.password2) {
		pwdMatch = false;
	}
	const doFederatedSignup = (e: any, authProvider: string) => {
		// if (window.location.hostname === "localhost") {
		// 	window.location.href = "http://localhost:3001/auth/google/signup?redirectUrl=" + encodeURIComponent("http://localhost:3000");
		// } else {
		// 	window.location.pathname = "/auth/google/signup";
		// }
		if (e)
			e.preventDefault();
		
		const organization = login.organization;
		let redirect = "/";
		let origin = window.location.origin;
		const originParam = "&origin=" + encodeURIComponent(origin);
		if (window.location.hostname === "localhost") {
			redirect = origin + redirect;
			origin = "http://localhost:3001"; // proxy will try to handle localhost:3000 and just do nop.
		}
		const url = origin + "/auth/" + authProvider + "/signup/" + organization + "?redirectUrl=" + encodeURIComponent(redirect) + originParam;
		window.location.assign(url);
	}
	const onLoginWithGoogle = (e: any) => doFederatedSignup(e, "google");
	const onLoginWithApple = (e: any) => doFederatedSignup(e, "apple");
	const onLoginWithFacebook = (e: any) => doFederatedSignup(e, "facebook");

	return (<form><fieldset className="loginForm" disabled={isSending}>
		<div className="formItem" style={{display:initOrg?"none" : "flex"}}>
			<label className="formLabel" htmlFor="org">Organization:</label>
			<input id="org" type="text" value={login.organization} onChange={e => setLogin({ ...login, organization: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="email">Name:</label>
			<input id="email" type="email" value={login.person} onChange={e => setLogin({ ...login, person: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="email">Email:</label>
			<input id="email" type="email" value={login.email} onChange={e => setLogin({ ...login, email: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="password">Password:</label>
			<input id="password" type="password" value={login.password} onChange={e => setLogin({ ...login, password: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="password">Confirm&nbsp;Password:</label>
			<input id="password" type="password" value={login.password2} onChange={e => setLogin({ ...login, password2: e.target.value })} />
		</div>
		<IconButton icon="at" style={{width:"100%",backgroundColor:"var(--back-color2)" }} disabled={!login.person || !login.email || !login.password || !pwdMatch || !login.organization} onClick={handleSubmit} label="Sign Up with Email"/>
		
		<div style={{width:"100%", display:"grid", gridTemplateColumns:"1fr auto 1fr", alignItems:"center", color:"#ccc"}}>
			<div style={{ width: "100%", height: "1px", borderTop: "1px solid #ccc", margin: "10px 0" }} />
			<span>&nbsp;OR&nbsp;</span>
			<div style={{width:"100%", height:"1px", borderTop:"1px solid #ccc", margin:"10px 0"}} />
		</div>

		<LoginWithGoogle label="Sign-up with Google" onClick={onLoginWithGoogle} disabled={!login.organization} />
		<LoginWithGoogle label="Sign-up with Facebook" className="loginWithFacebook" onClick={onLoginWithFacebook} disabled={!login.organization}>
		<svg aria-hidden="true" className="svg-icon iconFacebook" width="18" height="18" viewBox="0 0 18 18"><path fill="#4167B2" d="M3 1a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm6.55 16v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.28c-1 0-1.2.48-1.2 1.18V8.4h2.39l-.31 2.42h-2.08V17z"></path></svg>
		</LoginWithGoogle>
		<LoginWithGoogle label="Sign-up with Apple" className="loginWithApple" onClick={onLoginWithApple} disabled={!login.organization}>
		<svg height="44" viewBox="0 0 14 44" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m13.0729 17.6825a3.61 3.61 0 0 0 -1.7248 3.0365 3.5132 3.5132 0 0 0 2.1379 3.2223 8.394 8.394 0 0 1 -1.0948 2.2618c-.6816.9812-1.3943 1.9623-2.4787 1.9623s-1.3633-.63-2.613-.63c-1.2187 0-1.6525.6507-2.644.6507s-1.6834-.9089-2.4787-2.0243a9.7842 9.7842 0 0 1 -1.6628-5.2776c0-3.0984 2.014-4.7405 3.9969-4.7405 1.0535 0 1.9314.6919 2.5924.6919.63 0 1.6112-.7333 2.8092-.7333a3.7579 3.7579 0 0 1 3.1604 1.5802zm-3.7284-2.8918a3.5615 3.5615 0 0 0 .8469-2.22 1.5353 1.5353 0 0 0 -.031-.32 3.5686 3.5686 0 0 0 -2.3445 1.2084 3.4629 3.4629 0 0 0 -.8779 2.1585 1.419 1.419 0 0 0 .031.2892 1.19 1.19 0 0 0 .2169.0207 3.0935 3.0935 0 0 0 2.1586-1.1368z"></path></svg>
		</LoginWithGoogle>
		{isSending && <Loading />}
		{status.msg && <div style={{ color: status.err ? "red" : "green" }}>{status.msg}</div>}
		{!pwdMatch && <div style={{ color: "red" }}>Passwords do not match.</div>}
	</fieldset></form>);
}
